import { TableColumnProps } from 'components/table/table-component/table-component';

export const labeledTeamReportTableStructure: TableColumnProps[] = [
  {
    id: 'label',
    title: 'Label',
    position: 'left',
  },
  // {
  //   id: 'members_count',
  //   title: '# Members',
  // },
  {
    id: 'repos_count',
    title: '# Repositories',
  },
  {
    id: 'total_vulnerability_counts',
    title: '# Vulnerabilites',
    position: 'left',
  },
  // {
  //   id: 'num_total',
  //   title: 'Members',
  // },
  // {
  //   id: 'num_inactive',
  //   title: 'Inactive members',
  // },
  // {
  //   id: 'repos_count',
  //   title: 'Repositories',
  // },
];
